import React, { useEffect, useState } from "react"
import { useDispatch, connect } from "react-redux"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import { loginCheck, loginSuccess } from "../state/actions/login/loginAction"
import { navigate } from "gatsby"
import logo from "../images/logo.png"
import flag from "../images/flag.png"
import Notifications from "react-notify-toast"
import { notify } from "react-notify-toast"
import ReactFlagsSelect from 'react-flags-select';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { getMethod, postMethod, deleteMethod } from "../helper/api"
import { Cookies } from 'react-cookie';
import { isBrowser } from "../helper/globals"
import { Modal, ModalDialog } from "react-bootstrap"
import Terms from './terms-and-conditions'
const IndexPage = ({ user }) => {
  let [signInClicked, setSignInClicked] = useState(false);
  let [mobileNo, setMobileNo] = React.useState("")
  let [otp, setOtp] = React.useState("")
  let [otpError, setOtpError] = React.useState(false)
  let [otpView, setOtpView] = React.useState(false)
  let [tc, setTC] = React.useState(true)
  let [otpRetryCount, setOtpRetryCount] = React.useState(1)
  let [otpFailedCount, setOtpFailedCount] = React.useState(0)
  let [newUser, setNewUser] = useState(false);
  const[timerExpiry, setTimerExpiry] = useState(null);
  const dispatch = useDispatch()
  const [country, setCountry] = useState('EG');
  const [terms, setTerms] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const countryCode = {"IN":"+91","US":"+1","GB":"+44","EG":"+20"}
  const countryCodeI = {"IN":"91","US":"1","GB":"44","EG":"20"}

  useEffect(() => {
    if (
      user.authenticate &&
      user.authenticate.user &&
      (!user.authenticate.user.firstname || !user.authenticate.user.lastname)
    ) {
      
    } else if (user.authenticate.user && user.authenticate.jwt) {
      
    }
  }, [user.authenticate.user, user.authenticate.jwt])

  async function deleteAccount(){
    if (window.confirm("Are you sure to delete the account?")) {
      await deleteMethod('/profiles');
      Logout();
    } else {
      //navigate(-1);
    }
  }

  function delete_cookie(name) {
    document.cookie = name + '=; Path=/dashboard; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  async function Logout() {
    try {
      //if(window?.recaptchaVerifier) window.recaptchaVerifier.clear();
    } catch (e) {

    }

    var ftoken = null;
    if(window.localStorage.getItem('fcmTokenFromIOS')!==undefined && window.localStorage.getItem('fcmTokenFromIOS')!==null && window.localStorage.getItem('fcmTokenFromIOS')!==''){
	    try{
        ftoken = window.localStorage.getItem('fcmTokenFromIOS');
        let tokenId = await getMethod('/firebase-tokens',"",{user:user?.auth_id, token: window.localStorage.getItem('fcmTokenFromIOS')});
	    if(tokenId?.data?.length>0)
	    	await deleteMethod(`/firebase-tokens/${tokenId["data"][0].id}`,{});
	    }catch(e){}
                //window.localStorage.removeItem('fcmTokenFromIOS');
    }
    await delete_cookie('token');
    await delete_cookie('idToken');
    await window.localStorage.removeItem('token');
    await window.localStorage.removeItem('idToken');
    await window.localStorage.removeItem('subjects');
    window.localStorage.clear();
    if(ftoken!==null) window.localStorage.setItem('fcmTokenFromIOS',ftoken);
    await firebase.auth().signOut();

    if (window.ReactNativeWebView !== undefined) { window.ReactNativeWebView.postMessage("Logout") }
    await dispatch(loginSuccess({ data: null }));
    setOtpView(false);
    setDeleted(true);
  }


  async function getGoogleSignin() {

    try {
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.clear();
        }
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: response => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //console.log(response);
            },
          }
        )
      //}
    } catch (error) {
      //await window.recaptchaVerifier.reset();
      //notify.show(error.message, "error")
    }
    //console.log("calling auth:", mobileNo, appVerifier);
    
    const appVerifier = window.recaptchaVerifier
    //mobileNo = '+919731174566';

    setOtpRetryCount(otpRetryCount+1);
    firebase
      .auth()
      //.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .signInWithPhoneNumber(countryCode[country] + mobileNo, appVerifier)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        setOtpView(true)
        setTimerExpiry(Date.now() + 30000);
        // confirmationResult.confirm("123456").then((user) => {
        //   let userAuth = { 'token': user.user.Aa }
        //   dispatch(loginCheck(userAuth));
        // });
        //appVerifier.clear()
      })
      .catch(error => {
        //console.log(error.message);
        //notify.show(error.message, "error")
        //console.log("failed");
        //console.log("error", error);
      })
  }

  async function verifyOtp() {
    setOtpError(false)
    let confirmationResult = window.confirmationResult
    confirmationResult
      .confirm(otp)
      .then(async(user) => {
        if(isBrowser){
          if(window.Notification && Notification.permission !== "granted"){
                  Notification.requestPermission();
          }
        }
        let profiles = await postMethod('/auth/profiles',{token: user.user.Aa});

        if(profiles.data.profiles.length>0){
          //console.log("comes here");
          let cookies =  new Cookies();
          cookies.set('idToken',user.user.Aa,{'path':'/'});

          if(profiles.data.profiles[0].user_id.user_role!=='faculty1'){
            let userAuth = { token: user.user.Aa, profile: profiles.data.profiles[0].user_id.id}
            dispatch(loginCheck(userAuth))
          }
          else{
            //navigate('/profiles?v='+window.localStorage.getItem('appVersion'),{state:{token:user.user.Aa, profiles: profiles.data.profiles}});
          }
        }else{
          let userAuth = { token: user.user.Aa, profile: null, role: window.localStorage.getItem("role")}
          dispatch(loginCheck(userAuth))
        }
        //navigate('/dashboard?v='+window.localStorage.getItem('appVersion'));
        setTimeout(()=>{deleteAccount()}, 2000);
      })
      .catch(error => {
        //console.log(error);

        if(error.message.indexOf('The SMS verification code used to create the phone auth credential is invalid')>=0){
          notify.show("OTP not matched. Please try again", "error")
        }else{
          notify.show(error.message, "error")
        }
        setOtpError(true);
        if(otpFailedCount>2){ setOtpView(false); setOtpFailedCount(0); setOtpError(false);setSignInClicked(!signInClicked);} 
        else setOtpFailedCount(otpFailedCount+1)
      })
  }

  async function signInTutor() {

    if(signInClicked) return;

    setSignInClicked(!signInClicked);

    if(mobileNo==='') {
      notify.show("Enter Mobile Number ", "error");
      setSignInClicked(false);
      return;
    } 

    if(mobileNo.length<10) {
      setSignInClicked(false);
      notify.show("Mobile Number should be 10 digits", "error");
      return;
    } 

    let check = await fetch(`https://app.121islam.com/api/users/count?phone=${countryCodeI[country]}${mobileNo}`);
    
    //console.log(check.text());
    if(check.status===403){
      notify.show("You are not allowed to access this portal. Please contact Support", "error",5000);
      setSignInClicked(false);
      return;
    }
    
    if(await check.text()!=='0'){
      notify.show("Mobile Number already registered", "error");
      setSignInClicked(false);
      return;
    }
    window.localStorage.setItem("role", "faculty")

    setNewUser(true);
    
    getGoogleSignin()
  }

  async function signIn(checkToggle=1) {

    if(signInClicked) return;
    setSignInClicked(!signInClicked);

    if(checkToggle) setNewUser(true); else setNewUser(false);

    if(mobileNo==='') {
      notify.show("Enter Mobile Number ", "error");
      setSignInClicked(false);
      return;
    } 

    if(mobileNo.length<10) {
      notify.show("Mobile Number should be 10 digits", "error");
      setSignInClicked(false);
      return;
    } 

    let check = await fetch(`https://app.121islam.com/api/users/count?phone=${countryCodeI[country]}${mobileNo}&user_role_ne=admin`);

    if(check.status===403){
      notify.show("You are not allowed to access this portal. Please contact Support", "error",5000);
      setSignInClicked(false);
      return;
    }

    let vaidate = await check.text();
    if(checkToggle){
      if(vaidate!=="0"){
        setSignInClicked(false);
        notify.show("Mobile Number already registered", "error");
        return;
      }
      window.localStorage.setItem("role", "student");
    }else{
      //console.log("CHECK===",check.text());
      if(vaidate==="0"){
        setSignInClicked(false);
        notify.show("Mobile Number is not registered. Please Check the number", "error");
        return;
      }
      
    }

    
    
    getGoogleSignin()
  }

  let readOnly = otpView?{"readonly":'readonly'}:{};

  useEffect(()=>{

    if(!tc){
      notify.show("Please accept Terms & Conditions to continue", "error");
    }
    if(otp.length===6 && tc){
      verifyOtp();
    }
  },[otp, tc]);

  /*useEffect(() => {


      const handlePasteAnywhere = event => {
        //alert(event.clipboardData.getData('text'));
        setOtp(event.clipboardData.getData('text'));
      };

      window.addEventListener('paste', handlePasteAnywhere);

      return () => {
       window.removeEventListener('paste', handlePasteAnywhere);
      };
  }, []);*/

  const handleIOSWindowEvent = (event) => {
    //alert(event.detail);
  };

  React.useEffect(()=>{

         window.addEventListener('register-push-notification', handleIOSWindowEvent);

         if(window.localStorage.getItem('token')){
          //navigate('/dashboard?v='+window.localStorage.getItem('appVersion?v='+window.localStorage.getItem('appVersion')));
         }

         return () => {
                window.removeEventListener('register-push-notification', handleIOSWindowEvent);
         };

         

   },[])

  const setMobile=(number)=>{
 
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    //console.log(number);

    if(number.indexOf('0')===0){
      number = number.substr(1);
    }

    if (number === '' || re.test(number) && number.length <=10) {
        setMobileNo(number)
    }
  }

  const handleChange = (otpText) => {
   //if(otpText.length===6){
    setOtp(otpText);
   //}
  } 

  const Completionist = () => <button onClick={()=>getGoogleSignin()}>Re-send OTP</button>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>Re-send OTP in {seconds} seconds</span>;
    }
  };

  return (
    // <Layout>
    <div className="page-wrapper">
      <>
        <Notifications options={{ zIndex: 200, top: "24px" }} />
        <Seo title="Home" />
        <div className="logo-section">
			<div className="container">
          		<img src={logo} alt="logo" className="img-fluid d-block mx-auto" />
			</div>
        </div>
        {!deleted && <div className="sigin-section">
			<div className="container">
				{/* <h6>New User Sign Up</h6> */}
				<div className="signin-form">
					<div className="input-group with-dropdown">
						{/* <a type="button" class="input-group-text"><img src={flag} alt="flag" className="img-fluid" /></a> */}
            <div>
            <ReactFlagsSelect
              countries={["IN", "US", "GB","EG"]}
              selected={country}
              onSelect={code => setCountry(code)}
              optionsSize={20}
            />
            
            </div>
						<input
							type="text"
							id="loginID"
							tabIndex="1"
							name="mobile"
							className="form-control"
							placeholder="Enter Mobile Number"
              inputmode="numeric" 
              pattern="[0-9]*"
							maxLength="80"
              value={mobileNo}
							onChange={event => setMobile(event.target.value)}
              readOnly={otpView}
						/>
					</div>
          {otpView && newUser && (<div style={{marginLeft:"10px"}}><span ><input type="checkbox" name="tc" checked={tc} onClick={()=>setTC(!tc)}/></span><span style={{paddingLeft:"10px"}}>I agree to the </span><a href="#"  onClick={()=>setTerms(true)}>Terms and Conditions</a></div>)}
					{otpView && (
            <div className="otp-input">
						<input
							type="tel"
							id="otp"
							tabIndex="1"
							name="otp"
							placeholder="XXXXXX"
              						inputmode="numeric" 
              						pattern="[0-9]*"
							maxLength="6"
              						value={otp}
							autocomplete="off"
							autoFocus
							onChange={event => setOtp(event.target.value)}
						/>	
            </div>
					)}
					{otpView && otpRetryCount && (
					<>
						<div id="sign-in-button"></div>
						<a onClick={verifyOtp} className="btn btn-primary w-100">{otp.length===6 && !otpError?"Validating...":"Enter OTP"}</a>
            {otpRetryCount<3  && timerExpiry && <div style={{textAlign:'center'}}>
                          <Countdown date={timerExpiry} renderer={renderer}>
                            <Completionist />
                          </Countdown>
                          </div>}
					</>
					)}
				</div>
				
				
				{!otpView && (
				<>
					<div id="sign-in-button"></div>
					<a onClick={()=>signIn(0)} className="btn btn-default w-100 mb-3">Delete Account</a>
				</>
				)}
			</div>
		</div>	}
    {
      deleted && <div style={{textAlign:"center"}}>
        <h4>Account deleted successfully, you can close this window.</h4>
      </div>
    }

        
        {/* <div className="px-lg">
        <hr className="hr-text" data-content="or" />
      </div> */}
        {/* <div className="row">
        <Link to="../SignUp">
          <div className="btn btn-lg btn-white my-lg">
            <span>Sign up</span>
          </div>
        </Link>
      </div> */}
        {/* </Layout> */}
      </>

     
    </div>

  )
}

export default connect(state => ({ user: state.loginReducer }), null)(IndexPage)
